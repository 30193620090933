/**
 * ==============================================================
 * EOS - RESPONSIVE BOOTSTRAP MOBILE APP LANDING PAGE TEMPLATE
 * ==============================================================
 *
 * PALETTE:
 * #8aba56
 * #7dad49
 * #71a13d
 * #649430
 * #578723
 * #4a7a16
 * #3e6e0a
 */

/**
 * ========================================
 * MAIN LAYOUT
 * ========================================
 */

.accent-background-overlay {
	background-color: #8aba56;
}

/* BOOTSTRAP PRIMARY COLOR */
a {
	color: #0081cb;
}
a:focus, a:hover {
	color: #0081cb;
}
.text-primary {
	color: #8aba56;
}
a.text-primary:hover {
	color: #0081cb;
}
.bg-primary {
	background-color: #8aba56;
}
a.bg-primary:hover {
	background-color: #7dad49;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
	background-color: #8aba56;
}
.btn-primary .badge {
	color:#0268a2	;
}
.btn-link {
	color: #0268a2;
}
.btn-link:hover,
.btn-link:focus {
	color: #7dad49;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	background-color: #8aba56;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	border-color: #8aba56;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
	background-color: #8aba56;
}
.pagination > li > a,
.pagination > li > span {
	color: #8aba56;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #7dad49;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #8aba56;
	border-color: #8aba56;
}
.label-primary {
	background-color: #7dad49;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
	background-color: #8aba56;
}
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
	color: #8aba56;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
	border-color: #8aba56;
}
.progress-bar {
	background-color: #8aba56;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
	background-color: #8aba56;
	border-color: #8aba56;
}
.panel-primary {
	border-color: #8aba56;
}
.panel-primary > .panel-heading {
	background-color: #8aba56;
	border-color: #8aba56;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #8aba56;
}
.panel-primary > .panel-heading .badge {
	color: #8aba56;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #8aba56;
}

/**
 * ========================================
 * HEADINGS
 * ========================================
 */


/**
 * ========================================
 * LIST
 * ========================================
 */

.features-icon-list-item:hover i {
	background-color: #0268a2;
}
.list-with-icons li > i[class*="icon-"],
.list-with-icons li > i[class*="fa-"] {
	color: #8aba56;
}

/**
 * ========================================
 * HEADER SECTION
 * ========================================
 */

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li.current > a {
	color:#0268a2;
}

/**
 * ========================================
 * PRICING SECTION
 * ========================================
 */

.pricing-package-items li > i.fa-check {
	color: #8aba56;
}
.pricing-table li:nth-child(1) .pricing-package-strip {
	background-color: #8aba56;
}
.pricing-table li:nth-child(2) .pricing-package-strip {
	background-color: #649430;
}
.pricing-table li:nth-child(3) .pricing-package-strip {
	background-color: #3e6e0a;
}

/**
 * ========================================
 * FOOTER SECTION
 * ========================================
 */

.social-media-links li a:focus, .social-media-links li a:hover {
	background-color: #0268a2	;
}

/**
 * JPRELOADER
 */

#jpreBar {
	background-color: #0268a2;
}